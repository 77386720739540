<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="users">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div id="data-list-search-rank" style="width: 80px; height: 40px; padding-top: 0;">
          <vs-dropdown class="flex flex-wrap-reverse">
            <a class="flex items-center" href="#">
              <span class="mr-2">{{ factorToText(renderMethod.factor) }}</span>
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="renderMethod.factor='account_id'">아이디</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='name'">이름</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='email'">이메일</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='hp'">전화</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='register_date'">가입일</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div
            class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span
              class="mr-2"
            >{{ ((currentx - 1) * renderMethod.itemsPerPage) + 1 }} - {{ numOfUsers >= currentx * renderMethod.itemsPerPage ? (currentx * renderMethod.itemsPerPage) : numOfUsers }} of {{ numOfUsers }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(20)">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>구분</vs-th>
        <vs-th>아이디</vs-th>
        <vs-th>이름</vs-th>
        <vs-th>회사</vs-th>
        <vs-th>부서</vs-th>
        <vs-th>직위</vs-th>
        <vs-th>가입일</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div @click="showUserInfo(tr)">{{ tr.cno ? '기업' : '개인' }}</div></vs-td>
            <vs-td><div @click="showUserInfo(tr)">{{ tr.id }}</div></vs-td>
            <vs-td><div @click="showUserInfo(tr)">{{ tr.name }}</div></vs-td>
            <vs-td><div @click="showUserInfo(tr)">{{ tr.office }}</div></vs-td>
            <vs-td><div @click="showUserInfo(tr)">{{ tr.post }}</div></vs-td>
            <vs-td><div @click="showUserInfo(tr)">{{ tr.position }}</div></vs-td>
            <vs-td><div @click="showUserInfo(tr)">{{ tr.reg_date }}</div></vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div> -->

     <!-- Start: 계정 수정 팝업 기업 -->
    <vs-popup classContent="popup-example" title="계정 수정" :active.sync="editUserModalActiveCom">
      <div class="edit-user-form">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>IP</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <span class="w-full" placeholder="없음">{{ userData.ip }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>이름</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.name"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>아이디</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" disabled v-model="userData.account_id"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>암호</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" type="password" v-model="userData.password"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>이메일</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" type="email" v-model="userData.email"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>전화</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.hp"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>회사</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.office"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>부서</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.post"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>직위</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.position"/>
              </div>
            </div>
            <!-- <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>승인</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="userData.grant" :clearable="false"
                    :options="userModalData.optionData.grantOption"/>
                </div>
              </div>
            </div> -->
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메일링</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div v-if="renderMethod.type" class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="userData.mailing" :clearable="false"
                    :options="userModalData.optionData.mailingOption"/>
                </div>
                <div v-else >
                  <md-checkbox v-model="userData.pemailing">화학</md-checkbox>
                  <md-checkbox v-model="userData.semailing">철강</md-checkbox>
                  <md-checkbox v-model="userData.bemailing">조선/물류</md-checkbox>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>상호(법인명)</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.combubin"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>사업자등록번호</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.com_num"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>대표자</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.president"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>종목/업종</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.item"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>업태</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.condition"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>법인주소</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input size="small" class="w-full" placeholder="없음" v-model="userData.addr"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <h3 style="text-align: center;">입금정보</h3>
            <div class="vx-row mb-6" style="max-height: 100px; overflow-y: scroll;">
              <table>
                <div class="" :key="idx" v-for="(tr, idx) in userData.list">
                  <tr v-if="tr.cno" style="text-align: center;">
                    <td style="width: 110px;">{{tr.money}}원</td>
                    <td>{{tr.s_date}}~</td>
                    <td>{{tr.e_date}}</td>
                    <td style="width: 80px;">{{tr.pay_name}}</td>
                    <td>{{tr.oksign ? tr.oksign.text : '거부'}}</td>
                  </tr>
                </div>
              </table>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>입금자</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="payInfo.pay_name" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>금액</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex">
                <vs-input style="width: 100px;" placeholder="없음" v-model="payInfo.money"/><p style="line-height: 37px; padding: 0 0 0 10px;">원</p>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>입금일</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker type="date" v-model="payInfo.pay_date" lang="kr" style="width: 200px;" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>기간</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker type="date" range v-model="dateRange" lang="kr" style="width: 200px;" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>승인</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="payInfo.oksign" :clearable="false"
                    :options="userModalData.optionData.grantOption" />
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메모</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <textarea style="height: 200px;" class="w-full" placeholder="없음" v-model="payInfo.memo" />
              </div>
            </div>
            <div class="vx-row mb-6 flex" style="justify-content: center;">
              <vs-button class="mr-3 mb-2" @click="updatePayInfo()">입금정보 수정</vs-button>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="editUser()">수정</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="editUserModalInfoCancelModalActive=true"
            >취소</vs-button>
          </div>
          <div class="demo-alignment2">
            <vs-popup classContent="popup-example2" title="수정 취소" :active.sync="editUserModalInfoCancelModalActive">
              <p style="padding: 20px">취소하시겠습니까?</p>
              <div style="padding:20px; display: inline-block;">
              <vs-button @click="userData={}; editUserModalInfoCancelModalActive=false; editUserModalActiveCom=false;" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="editUserModalInfoCancelModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- End: 계정 수정 팝업 기업 -->



     <!-- Start: 계정 수정 팝업 개인 -->
    <vs-popup classContent="popup-example" title="계정 수정" :active.sync="editUserModalActiveInd">
      <div class="edit-user-form">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>IP</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <span class="w-full" placeholder="없음">{{ userData.ip }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>이름</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userData.name"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>아이디</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" disabled v-model="userData.account_id"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>암호</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" type="password" v-model="userData.password"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>이메일</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" type="email" v-model="userData.email"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>전화</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userData.hp"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>회사</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userData.office"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>부서</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userData.post"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>직위</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userData.position"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>승인</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="userData.grant" :clearable="false"
                    :options="userModalData.optionData.grantOption"/>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메일링</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div v-if="renderMethod.type" class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="userData.mailing" :clearable="false"
                    :options="userModalData.optionData.mailingOption"/>
                </div>
                <div v-else >
                  <md-checkbox v-model="userData.mailing">산업</md-checkbox>
                  <md-checkbox v-model="userData.pemailing">화학</md-checkbox>
                  <md-checkbox v-model="userData.semailing">철강</md-checkbox>
                  <md-checkbox v-model="userData.bemailing">조선/물류</md-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <h3 style="text-align: center;">입금정보</h3>
            <div class="vx-row mb-6" style="max-height: 100px; overflow-y: scroll;">
              <table>
                <div class="" :key="idx" v-for="(tr, idx) in userData.list">
                  <tr v-if="tr.mno" style="text-align: center;">
                    <td style="width: 110px;">{{tr.money}}원</td>
                    <td>{{tr.s_date}}~</td>
                    <td>{{tr.e_date}}</td>
                    <td style="width: 80px;">{{tr.pay_name}}</td>
                    <td>{{tr.oksign ? tr.oksign.text : '거부'}}</td>
                  </tr>
                </div>
              </table>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>입금자</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="payInfo.pay_name" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>금액</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex">
                <vs-input style="width: 100px;" placeholder="없음" v-model="payInfo.money" /><p style="line-height: 37px; padding: 0 0 0 10px;">원</p>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>입금일</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker type="date" v-model="payInfo.pay_date" lang="kr" style="width: 200px;" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>기간</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker type="date" range v-model="dateRange" lang="kr" style="width: 200px;" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>승인</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="payInfo.oksign" :clearable="false"
                    :options="userModalData.optionData.grantOption" />
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메모</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <textarea style="height: 200px;" class="w-full" placeholder="없음" v-model="payInfo.memo" />
              </div>
            </div>
            <div class="vx-row mb-6 flex" style="justify-content: center;">
              <vs-button class="mr-3 mb-2" @click="updatePayInfo()">입금정보 수정</vs-button>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="editUser()">수정</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="editUserModalInfoCancelModalActive=true"
            >취소</vs-button>
          </div>
          <div class="demo-alignment2">
            <vs-popup classContent="popup-example2" title="수정 취소" :active.sync="editUserModalInfoCancelModalActive">
              <p style="padding: 20px">취소하시겠습니까?</p>
              <div style="padding:20px; display: inline-block;">
              <vs-button @click="userData={}; editUserModalInfoCancelModalActive=false; editUserModalActiveInd=false;" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="editUserModalInfoCancelModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- End: 계정 수정 팝업 개인 -->

  </div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
      editUserModalActiveCom: false,
      editUserModalActiveInd: false,
      editUserModalInfoCancelModalActive: false,
      userModalData: {
        optionData: {
          grantOption: [
            { text: "승인", value: "1" },
            { text: "거부", value: "0" }
          ],
        },
      },
      dateRange: [new Date(), new Date()],
      users: [],
      userData: {},
      payInfo: {},
      currentx: 1,
      numOfUsers: 0,
      numOfPages: 0,
      searchText: "",
      renderMethod: {
        startRow: 0,
        itemsPerPage: 15,
        factor: "name",
        inputText: ""
      },
      isMounted: false,
    };
  },
  beforeRouteLeave (to, from, next) {
    this.editUserModalActiveCom = false;
    this.editUserModalActiveInd = false;
    this.editUserModalInfoCancelModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      this.renderMethod.startRow = (this.currentx-1) * this.renderMethod.itemsPerPage
      this.getUserList()
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      this.getUserList()
    },
    
    factorToText(menu) {
      let menuText;
      switch (menu) {
        case "name":
          menuText = "이름";
          break;
        case "account_id":
          menuText = "아이디";
          break;
        case "email":
          menuText = "이메일";
          break;
        case "hp":
          menuText = "전화";
          break;
        case "register_date":
          menuText = "가입일";
          break;
      }
      return menuText;
    },
    getUserList() {
      const thisIns = this;
      // this.$vs.loading({
      //   container: "#button-with-loading",
      //   scale: 0.6
      // });
      
      this.$http
        .post("/api/manageUsers/commonlist/new_pay", thisIns.buildSearchParam())
        .then(function(response) {
          console.log(response)
          // thisIns.numOfUsers = response.data[0][0].user_count
          // thisIns.numOfPages = Math.ceil(response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage)
          // thisIns.users = response.data[1]
          thisIns.users = response.data;

          // thisIns.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    buildSearchParam() {

      var param = {};

      // param['startRow'] = this.renderMethod.startRow
      // param['itemsPerPage'] = this.renderMethod.itemsPerPage
      // param['factor'] = this.renderMethod.factor
      // param['searchText'] = this.searchText

      return param
    },
    mapUserInfoAtEditModal(userInfo){
      if(userInfo.cno){
        this.userData = userInfo;
      }else{
        this.userData = userInfo;
      }
      this.userData.account_id = this.userData.id;
      this.userData.password = '';
      this.userData.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.mem_ok) || this.userModalData.optionData.grantOption[1];
      if(userInfo.list.length == 0){
        userInfo.list = [{}];
      }
      for(let payInfo of userInfo.list){
        payInfo.pay_date = new Date(payInfo.pay_date);
        payInfo.oksign = payInfo.oksign == 1 ? this.userModalData.optionData.grantOption[0] : this.userModalData.optionData.grantOption[1];
      }
      this.payInfo = userInfo.list[0];
      if(!this.payInfo.s_date || this.payInfo.s_date == '0000-00-00'){ this.payInfo.s_date = '0000-00-00'; }
      if(!this.payInfo.e_date || this.payInfo.e_date == '0000-00-00'){ this.payInfo.e_date = '0000-00-00'; }
      this.dateRange = [new Date(this.payInfo.s_date), new Date(this.payInfo.e_date)];
    },

    showUserInfo(userInfo) {

      const thisObj = this;
      let reqPath = "/api/manageUsers/company/get";
      let params = {cno: 0, type: ''};
      if(userInfo.cno){
        reqPath = "/api/manageUsers/company/get";
        params = {cno: userInfo.cno, type: ''};
      }else{
        reqPath = "/api/manageUsers/individual/get";
        params = {mno: userInfo.mno, type: ''};
      }
      thisObj.$http
          .post(reqPath, params)
          .then(function(response) {
            console.log(response.data)
            thisObj.mapUserInfoAtEditModal(response.data)
            if(userInfo.cno){
              thisObj.editUserModalActiveCom = true
            }else{
              thisObj.editUserModalActiveInd = true
            }
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },
    editUser(){
      const thisObj = this;
      let reqPath = "/api/manageUsers/company/edit";
      let params = JSON.parse(JSON.stringify(this.userData));
      params.type = '';
      params.target_account_id = params.account_id;
      params.grant = params.grant.value;
      params.mailing = params.mailing ? 1 : 0;
      params.pemailing = params.pemailing ? 1 : 0;
      params.bemailing = params.bemailing ? 1 : 0;
      params.semailing = params.semailing ? 1 : 0;

      if(this.userData.cno){
        console.log('기업');
        reqPath = "/api/manageUsers/company/edit";
      }else{
        console.log('개인');
        reqPath = "/api/manageUsers/individual/edit";
      }
      thisObj.$http
          .post(reqPath, params)
          .then(function(response) {
            if(response.data === "success") {
              thisObj.$vs.notify({
                title:'계정 수정 완료',
                text:"계정 '"+thisObj.userData.account_id+"' 수정 완료.",
                color:'success'})
              thisObj.editUserModalActiveCom = false;
              thisObj.editUserModalActiveInd = false;
              thisObj.userData = {};
              thisObj.getUserList();
            }
            else {
              thisObj.$vs.notify({
                title:'계정 수정 실패',
                text:"계정 '"+thisObj.userData.account_id+"' 수정 실패.",
                color:'danger'})
            }
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },
    updatePayInfo(){
      const thisObj = this;
      let reqPath = "/api/manageUsers/company/update_pay_info";
      let params = JSON.parse(JSON.stringify(this.payInfo));
      params.s_date = this.dateRange[0];
      params.e_date = this.dateRange[1];
      params.oksign = params.oksign.value;

      if(this.payInfo.cno){
        console.log('기업');
        reqPath = "/api/manageUsers/company/update_pay_info";
      }else{
        console.log('개인');
        reqPath = "/api/manageUsers/individual/update_pay_info";
      }
      thisObj.$http
          .post(reqPath, params)
          .then(function(response) {
            if(response.data && response.data.state == 0){
              thisObj.editUserModalActiveCom = false;
              thisObj.editUserModalActiveInd = false;
              thisObj.getUserList();
              thisObj.$vs.notify({
                title: "성공",
                text: '수정 성공',
                color: "primary",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
            }else{
              throw Error('send fail');
            }
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },

  created() {
      const thisIns = this;
      this.$http
        .post("/api/manageUsers/commonlist/new_pay", thisIns.buildSearchParam())
        .then(function(response) {
          console.log(response)
          // thisIns.numOfUsers = response.data[0][0].user_count
          // thisIns.numOfPages = Math.ceil(response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage)
          // thisIns.users = response.data[1]
          thisIns.users = response.data;
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-common.scss";
</style>